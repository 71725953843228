import { getDownloadURL } from "firebase/storage";

export const useUploadSubmissionToFirestore = async ({
  file,
  documentId,
}: {
  file?: File | CustomFile;
  documentId: string;
}) => {
  const uid = useCurrentUID();

  if (!uid) return;

  const finalFile = file;

  if (finalFile == undefined) return;

  const mediaObject = await useFileUploader().createMediaObject(
    finalFile,
    `/documents/${documentId}`,
    false
  );

  let fileData: any = file;

  // check if file is a custom file
  if ((file as CustomFile).data) {
    fileData = (file as CustomFile).data;
  }

  const task = await useFileUploader().uploadFile(fileData, mediaObject);
  mediaObject.mediaHref = await getDownloadURL(task.ref);

  console.log("mediaObject", mediaObject);

  return mediaObject;
};
