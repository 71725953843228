import { useModal } from "vue-final-modal";
import DocumentsEditModal from "~/components/Documents/EditModal.vue";

export const useOpenDocumentEditModal = (
  submittedDocument: SubmittedDocument
) => {
  const { open, close } = useModal({
    component: DocumentsEditModal,
    attrs: {
      document: submittedDocument,
      onClose: () => {
        close();
      },
    },
  });

  open();
};
