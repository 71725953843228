<script lang="ts" setup>
import ConfirmDialog from "primevue/confirmdialog";
import type { Checklist, Checklists } from "types/Checklist";
import { type SubmittedDocument } from "types/SubmittedDocument";
import { useModal } from "vue-final-modal";
import { Assignment } from "~/classes/models/assignments/assignment.model";
import { Classroom } from "~/classes/models/classrooms/classroom.model";
import {
  Student,
  type Students,
} from "~/classes/models/students/student.model";

import { getDownloadURL } from "firebase/storage";
import UploadStudentSubmissionDialog from "~/components/UploadStudentSubmission/Dialog.vue";
import { DocumentState } from "~/types/enums/DocumentState";

import { addDoc, collection } from "firebase/firestore";
import { DocumentSubmissionType } from "~/types/enums/DocumentSubmissionType.enum";

declare const google: any;

const props = defineProps({
  checklist: {
    type: Object as PropType<Checklist>,
    required: false,
  },
  checklistId: {
    type: String,
    required: false,
  },
  classrooms: {
    type: Array as PropType<Classroom[]>,
    required: false,
  },
  assignments: {
    type: Array as PropType<Assignment[]>,
    required: false,
  },
  classroom: {
    type: Object as PropType<Classroom>,
    required: false,
  },
  assignment: {
    type: Object as PropType<Assignment>,
    required: false,
  },
  checklists: {
    type: Array as PropType<Checklists>,
    required: false,
  },
  students: {
    type: Array as PropType<Students>,
    required: false,
  },
  student: {
    type: Object as PropType<Student>,
    required: false,
  },
  isGoogleImportEnabled: {
    type: Boolean,
    required: false,
    default: true,
  },
  showSamples: {
    type: Boolean,
    required: false,
    default: false,
  },
  documentSubmissionType: {
    type: String as PropType<DocumentSubmissionType>,
    required: false,
    default: () => DocumentSubmissionType.finalDraft,
  },
  submittedDocumentState: {
    type: String as PropType<DocumentState>,
    required: false,
    default: () => DocumentState.submitted,
  },
  acceptedFileTypes: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [
      "application/vnd.google-apps.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },
  popupPlacement: {
    type: String,
    default: "bottom",
  },
});

const emit = defineEmits(["on-file-option-selected"]);

const appDataStore = useAppUserData();
const { isPublicDemo } = storeToRefs(appDataStore);


const selectedChecklist = ref<Checklist | undefined>(props.checklist);
const selectedStudent = ref<Student | undefined>(props.student);
const selectedClassroom = ref<Classroom | undefined>(props.classroom);
const selectedAssignment = ref<Assignment | undefined>(props.assignment);

const { createDocument, updateDocumentStateById } = useDocuments();

const fileRef = ref<HTMLElement | undefined>();

const uploadFile = () => {
  if(isPublicDemo.value ) {
    useBaseAlert("Oops!", "You can’t upload student submissions in demo mode.");
    return;
  }

  emit("on-file-option-selected");
  fileRef.value?.click();
};

const isUploading = computed((): boolean => {
  return filesBeingUploaded.value.length > 0;
});

// Every time we're uploading a file we'll add the document id to this array
// Once the api request finish processing we'll remove the id from the array
const filesBeingUploaded = ref<string[]>([]);

const files = ref<any[]>([]);

const onFileSelected = async (ev: any) => {
  files.value = [...ev.target.files];
  ev.target.files = null;
  ev.target.value = "";

  if (files.value.length == 0) {
    return;
  }

  if (props.checklists || props.students) {
    showConfigurationModal();
  } else {
    uploadFiles();
  }
};

const showConfigurationModal = (method: "google" | "computer" = "computer") => {
  const { open, close } = useModal({
    component: UploadStudentSubmissionDialog,
    attrs: {
      classrooms: props.classrooms,
      assignments: props.assignments,
      checklists: props.checklists,
      students: props.students,
      onUpload: (data) => {
        const { checklist, student, classroom, assignment } = data;

        selectedClassroom.value = classroom ?? props.classroom;
        selectedAssignment.value = assignment ?? props.assignment;

        selectedChecklist.value = checklist;
        selectedStudent.value = student;

        close();
        uploadFiles(method);
      },
      onClose: () => {
        close();
      },
    },
  });

  open();
};

const uploadFiles = async (method: "google" | "computer" = "computer") => {
  const documentParams = useCreateDocumentParams({
    classroom: selectedClassroom.value,
    assignment: selectedAssignment.value,
    student: selectedStudent.value,
    checklist: selectedChecklist.value,
    checklists: props.checklists ?? [],
    documentSubmissionType: props.documentSubmissionType,
  });

  const wordLikeFiles = files.value.filter((file) => {
    return [
      "application/vnd.google-apps.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.type);
  });

  const googleSheetFiles = files.value.filter((file) => {
    return ["application/vnd.google-apps.spreadsheet"].includes(file.type);
  });

  const otherFiles = files.value.filter((file) => {
    return (
      [
        "application/vnd.google-apps.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.google-apps.spreadsheet",
      ].includes(file.type) != true
    );
  });

  for (const googleSheetFile of googleSheetFiles) {
    const fileId = googleSheetFile.fileId;
    documentParams.googleDriveFileId = fileId;

    const documentId = await createDocument(
      googleSheetFile.name,
      documentParams
    );

    filesBeingUploaded.value.push(documentId);

    // Export the google drive file as an excel file
    const response = await fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      {
        headers: new Headers({
          Authorization: "Bearer " + accessToken.value,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server responded with ${response.status}: ${response.statusText}`
      );
    }

    const blob = await response.blob();

    const customFile = {
      data: blob,
      name: `${googleSheetFile.name}.xlsx`,
      size: blob.size,
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    } as CustomFile;
    const mediaObject = await useUploadSubmissionToFirestore({
      file: customFile,
      documentId,
    });

    if (mediaObject == undefined) {
      continue;
    }

    await useCreateMediaRevision({
      documentId,
      mediaObject,
      submittedDocumentState: props.submittedDocumentState,
    });

    filesBeingUploaded.value = [
      ...filesBeingUploaded.value.filter((id) => id !== documentId),
    ];
  }

  for (const otherFile of otherFiles) {
    const fileId = otherFile.fileId;
    documentParams.googleDriveFileId = fileId;

    const documentId = await createDocument(otherFile.name, documentParams);
    filesBeingUploaded.value.push(documentId);

    // These files are easy, I just upload hte documetn to firebase storage and create a revision.
    let file: File | CustomFile | undefined = otherFile;

    if (method == "google") {
      if (accessToken.value == undefined) {
        continue;
      }

      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
        {
          headers: new Headers({
            Authorization: "Bearer " + accessToken.value,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Server responded with ${response.status}: ${response.statusText}`
        );
      }

      const blob = await response.blob();

      const customFile = {
        data: blob,
        name: otherFile.name,
        size: blob.size,
        type: otherFile.type,
      } as CustomFile;

      file = customFile;
    }

    const mediaObject = await useUploadSubmissionToFirestore({
      file,
      documentId,
    });

    if (mediaObject == undefined) {
      continue;
    }

    await useCreateMediaRevision({
      documentId,
      mediaObject,
      submittedDocumentState: props.submittedDocumentState,
    });

    filesBeingUploaded.value = [
      ...filesBeingUploaded.value.filter((id) => id !== documentId),
    ];
  }

  for (const wordLikeFile of wordLikeFiles) {
    documentParams.googleDriveFileId = wordLikeFile.fileId;

    const documentId = await createDocument(wordLikeFile.name, documentParams);

    filesBeingUploaded.value.push(documentId);

    const isLargeBulkUpload = wordLikeFiles.length > 5;
    const isLargeFile = wordLikeFile.size > 4.25 * 1024 * 1024;

    if (isLargeBulkUpload || isLargeFile) {
      // I'm going to upload the file to firebase storage:
      useUploadSubmissionToFirestore({
        file: wordLikeFile,
        documentId,
      }).then(async (mediaObject) => {
        if (mediaObject == undefined) {
          filesBeingUploaded.value = [
            ...filesBeingUploaded.value.filter((id) => id !== documentId),
          ];
          return;
        }

        // then here I'm going to create a task.
        await useCreateImportBulkSubmissionTask({
          documentId,
          mediaObject,
        });

        filesBeingUploaded.value = [
          ...filesBeingUploaded.value.filter((id) => id !== documentId),
        ];

    
      });

      continue;
    }

    if (method == "google") {
      useFetch(`/api/documents/${documentId}/parse/google`, {
        method: "POST",
        headers: await useApiHeaders(),
        body: {
          fileId: wordLikeFile.fileId,
          fileName: wordLikeFile.name,
          fileType: wordLikeFile.type,
          submittedDocumentState:
            props.submittedDocumentState ?? DocumentState.submitted,
          accessToken: accessToken.value,
        },
      }).then(() => {
        filesBeingUploaded.value = [
          ...filesBeingUploaded.value.filter((id) => id !== documentId),
        ];
      });
      continue;
    }

    const formData = new FormData();

    formData.append("file", wordLikeFile);
    formData.append("fileName", wordLikeFile.name);
    formData.append("fileType", wordLikeFile.type);
    formData.append("method", method);
    formData.append(
      "submittedDocumentState",
      props.submittedDocumentState ?? DocumentState.submitted
    );

    $fetch(`/api/documents/${documentId}/parse`, {
      method: "POST",
      headers: await useApiHeaders(),
      body: formData,
    }).finally(() => {
      filesBeingUploaded.value = [
        ...filesBeingUploaded.value.filter((id) => id !== documentId),
      ];
    });

    continue;
  }

  files.value = [];
};

const uploadDocumentToFirestore = async ({
  file,
  customFile,
  documentId,
}: {
  file?: File;
  customFile?: CustomFile;
  documentId: string;
}) => {
  const uid = useCurrentUID();

  if (!uid) return;

  const finalFile = file ?? customFile;

  if (finalFile == undefined) return;

  const mediaObject = await useFileUploader().createMediaObject(
    finalFile,
    `/documents/${documentId}`,
    false
  );

  // Check if file is a File or CustomFile

  if (!file && !customFile) return;

  const task = await useFileUploader().uploadFile(
    file ?? customFile!.data,
    mediaObject
  );
  mediaObject.mediaHref = await getDownloadURL(task.ref);

  // I should create the media object instead. Let's see.
  const revisionRef = collection(
    useFirestore(),
    `/documents/${documentId}/revisions`
  );

  await addDoc(revisionRef, {
    documentId,
    externalId: documentId,
    lastUpdatedTimestamp: Date.now(),
    fileObject: mediaObject,
  });

  // Update the document state
  await useDocuments().updateDocumentStateById(
    documentId,
    props.submittedDocumentState
  );
};

const onPickGoogle = () => {

  if(isPublicDemo.value ) {
    useBaseAlert("Oops!", "You can’t upload student submissions in demo mode.");
    return;
  }

  emit("on-file-option-selected");
  signInToGoogle(); // User is not signed in, handle authentication
};

const accessToken = ref<any | undefined>(undefined);

const signInToGoogle = async () => {
  const tokenClientState = useState("tokenClient");

  // Request an access token.
  (tokenClientState.value as any).callback = async (response: any) => {
    if (response.error !== undefined) {
      throw response;
    }
    const accessToken = response.access_token;
    openGooglePicker(accessToken);
  };

  if (accessToken.value === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    (tokenClientState.value as any).requestAccessToken({ prompt: "consent" });
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    (tokenClientState.value as any).requestAccessToken({ prompt: "" });
  }
};

const openGooglePicker = (token: any) => {
  accessToken.value = token;

  const view = new google.picker.DocsView()
    .setIncludeFolders(true)
    .setSelectFolderEnabled(false)
    .setMimeTypes(props.acceptedFileTypes.join(","));

  const picker = new google.picker.PickerBuilder()
    .addView(view)
    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
    .setOAuthToken(token)
    .setDeveloperKey(useRuntimeConfig().public.googleApiKey)
    .setCallback(pickerCallback)
    .setAppId(useRuntimeConfig().public.googleClientId)
    .build();
  picker.setVisible(true);
};

const pickerCallback = async (data: any) => {
  if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
    const documents = data[google.picker.Response.DOCUMENTS];

    // Handle the selected file here

    const googleFiles = [] as any[];
    for (const doc of documents) {
      googleFiles.push({
        fileId: doc.id,
        name: doc.name,
        type: doc.mimeType,
      });
    }

    files.value = googleFiles;

    if (props.checklists || props.students) {
      showConfigurationModal("google");
    } else {
      uploadFiles("google");
    }
  }
};

const sampleDocumentsStore = useSampleDocuments();
const { sampleDocuments } = storeToRefs(sampleDocumentsStore);

const importSampleDocument = async (sampleDocument: SubmittedDocument) => {
  
  if(isPublicDemo.value ) {
    useBaseAlert("Oops!", "You can’t upload student submissions in demo mode.");
    return;
  }


  const userId = useCurrentUID();

  if (!userId) return;

  if (!sampleDocument.id) return;

  await $fetch(`/api/documents/${sampleDocument.id}/sample/copy`, {
    headers: await useApiHeaders(),
  });
};

const allSamples = computed(() => {
  sampleDocuments.value.sort((a, b) => {
    return (a.sampleDisplayOrder ?? 0) - (b.sampleDisplayOrder ?? 0);
  });

  return sampleDocuments.value;
});

const firstSample = computed(() => {
  return allSamples.value[0];
});

const remaiingSamples = computed(() => {
  // return everythingf but the fist sample
  return allSamples.value.slice(1);
});

const acceptedFileTypeLabels = computed(() => {
  return props.acceptedFileTypes
    .map((fileType) => {
      switch (fileType) {
        case "application/vnd.google-apps.document":
          return "Google Doc";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "Word Document";
        case "application/pdf":
          return "PDF";
        case "image/*":
          return "Images";
        case "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "Excel";
        case "application/vnd.google-apps.spreadsheet":
          return "Google Sheet";
        default:
          return "Unknown";
      }
    })
    .filter((label) => label != "Unknown");
});
</script>

<template>
  <VDropdown
    :disabled="isGoogleImportEnabled != true"
    :placement="popupPlacement"
  >
    <BaseTextButton
      :show-spinner="isUploading"
      :class="{
        '!justify-start': true,
      }"
    >
      <ConfirmDialog group="upload">
        <template #message />
      </ConfirmDialog>

      <icon name="material-symbols:upload" size="20" />
      <span class="ml-1">
        <slot> Upload Student Submission </slot>
      </span>
      <input
        ref="fileRef"
        class="hidden"
        type="file"
        :accept="acceptedFileTypes.join(',')"
        multiple
        @change="onFileSelected"
      />
    </BaseTextButton>
    <template #popper>
      <div class="flex flex-col bg-surface">
        <div class="border-b">
          <BaseTextButton
            v-tooltip="acceptedFileTypeLabels.join(', ')"
            class="w-full"
          >
            <div class="w-full flex flex-row items-center justify-left">
              <icon name="material-symbols:info-outline" size="20" />
              <span class="ml-4 text-[14px] leading-1 mt-[1px]">
                Accepted File Types
              </span>
            </div>
          </BaseTextButton>
        </div>
        <BaseTextButton v-close-popper class="w-full" @click="uploadFile">
          <div class="flex flex-row items-center w-full">
            <icon name="material-symbols:upload" size="20" />
            <span class="ml-4"> From Computer </span>
          </div>
        </BaseTextButton>
        <BaseTextButton v-close-popper class="w-full" @click="onPickGoogle">
          <div class="flex flex-row items-center w-full">
            <icon name="logos:google-drive" size="20" />

            <span class="ml-4"> From Google Drive </span>
          </div>
        </BaseTextButton>
        <BaseTextButton
          v-if="showSamples && firstSample"
          :key="firstSample.id"
          v-close-popper
          class="w-[220px]"
          :data-step-id="`sample-document-button-${firstSample.name
            .toLowerCase()
            .split(' ')
            .join('-')}`"
          @click="importSampleDocument(firstSample)"
        >
          <div class="flex flex-row items-center w-full">
            <icon name="mi:document" size="20" />

            <span class="ml-4"> {{ firstSample.name }} </span>
          </div>
        </BaseTextButton>
        <VMenu v-if="showSamples" placement="right">
          <BaseTextButton>
            <div class="flex flex-row items-center w-full">
              <icon name="material-symbols:folder-outline" size="20" />

              <span class="ml-4"> Samples </span>
            </div>
          </BaseTextButton>

          <template #popper>
            <BaseTextButton
              v-for="sample in remaiingSamples"
              :key="sample.id"
              v-close-popper
              class="w-[220px]"
              :data-step-id="`sample-document-button-${sample.name
                .toLowerCase()
                .split(' ')
                .join('-')}`"
              @click="importSampleDocument(sample)"
            >
              <div class="flex flex-row items-center w-full">
                <icon name="mi:document" size="20" />

                <span class="ml-4"> {{ sample.name }} </span>
              </div>
            </BaseTextButton>
          </template>
        </VMenu>
      </div>
    </template>
  </VDropdown>
</template>

<style scoped></style>
