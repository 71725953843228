import { addDoc, collection } from "firebase/firestore";
import { DocumentState } from "~/types/enums/DocumentState";

export const useCreateMediaRevision = async ({
  documentId,
  mediaObject,
  submittedDocumentState,
}: {
  documentId: string;
  mediaObject: MediaObject;
  submittedDocumentState: DocumentState;
}) => {
  // I should create the media object instead. Let's see.
  const revisionRef = collection(
    useFirestore(),
    `/documents/${documentId}/revisions`
  );

  await addDoc(revisionRef, {
    documentId,
    externalId: documentId,
    lastUpdatedTimestamp: Date.now(),
    fileObject: mediaObject,
  });

  // Update the document state
  await useDocuments().updateDocumentStateById(
    documentId,
    submittedDocumentState
  );
};
