import { addDoc, collection } from "firebase/firestore";

export const useCreateImportBulkSubmissionTask = async ({
  documentId,
  mediaObject,
}: {
  documentId: string;
  mediaObject: MediaObject;
}) => {
  const db = useFirestore();

  const taskCollectionRef = collection(
    db,
    "/taskQueues/importBulkSubmission/queuedTasks"
  );

  await addDoc(taskCollectionRef, {
    documentId,
    fullPath: mediaObject.fullPath,
  });
};
